// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$small-and-up: 'only screen and (min-width : #{$small-screen})' !default;
$medium-and-up: 'only screen and (min-width : #{$medium-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;

$gcg-teal: #005c58;
$gcg-purple: #3b1a6e;
$light-grey: #616161;
$gcg-black: #222222;
$white: #ffffff;

html {
    font-family: Arial, sans-serif;
}

.modal-close-button {
    position: absolute;
    top: 18px;
    right: 20px;
    font-weight: bolder;
    font-size: 2em;
    line-height: 2rem;
}
.modal-close-button__icon {
    cursor: pointer;
}
.ReactModal__Content {
    @media #{$medium-and-down} {
        width: 95% !important;
    }
}
.recipient-builder {
    max-width: 482px;
}
.recipient-item-form {
    border: none;
    padding: 0;
    margin: 0;
}
.recipient-item-form__header {
    color: $gcg-teal;
    font-size: 15px;
    font-weight: bold;
    padding: 0;
}

.ag-theme-balham.cards-customization-grid {
    .ag-center-cols-clipper {
        min-height: 150px !important;
    }
    .ag-row-selected {
        background: rgba(119, 136, 152, 0.3) !important;
    }
    .ag-cell-focus {
        outline: none;
        &:not(.ag-cell-range-selected) {
            border: 0 !important;
        }
    }
    .ag-row {
        .ag-cell {
            border: 0;
            padding-right: 0;
            overflow: visible; // For 'apply value to column' dialog to show over row below cell(s)

            .ag-cell-wrapper {
                // Styling for selection checkbox
                align-items: flex-start;
                .ag-selection-checkbox {
                    padding-top: 1.5rem;
                }
            }

            &.ag-cell-inline-editing {
                // The cell currently being edited
                height: 100%;
                background: rgba(119, 136, 152, 0.3) !important;
                .ag-cell-edit-wrapper {
                    height: 100%;
                    .ag-input-field {
                        padding-left: 11px;
                        .ag-text-field-input-wrapper {
                            height: 70%;
                            .ag-text-field-input {
                                padding-left: 8px;
                                margin-right: 10px;
                                border-radius: 4px;
                                font-size: 13px;
                                &:focus {
                                    box-shadow: none;
                                    border: 1px solid #592263;
                                }
                            }
                        }
                    }
                }
            }

            .ag-cell-wrapper {
                height: 100%;
                .ag-cell-value {
                    width: 100%;
                    height: 100%;
                    .ag-react-container {
                        height: 100%;
                        .MuiOutlinedInput-input {
                            padding: 8px 8px 8px 0;
                            font-size: 13px;
                            color: $gcg-black;
                        }
                    }
                }
            }

            .ag-react-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .MuiOutlinedInput-input {
                    padding: 8px;
                    font-size: 13px;
                    color: $gcg-black;
                }
            }
        }
    }
}

.ag-theme-balham.refund-grid {
    .ag-row {
        .ag-cell {
            border: 0;
            padding-right: 0;
            display: flex;
            align-items: center;
            overflow-x: hidden;
            overflow-y: auto;

            .ag-cell-wrapper {
                // Styling for selection checkbox
                align-items: flex-start;
                .ag-selection-checkbox {
                    padding-top: 1.5rem;
                }
            }

            .ag-cell-value {
                align-self: center;
            }
        }
    }
}

// Begin orderCancelled.html.twig
.order-cancelled_heading-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    h2 {
        font-size: 30px;
    }
    @media #{$medium-and-up} {
        justify-content: flex-start;
        h2 {
            font-size: 2.6rem;
        }
    }

}
.order-cancelled_heading {
    font-weight: bold;
    font-size: 27px;
    margin-bottom: 2rem;
    margin-top: 8px;
    max-width: 166px;
    @media #{$small-and-up} {
        font-size: 48px;
    }
    @media #{$medium-and-up} {
        max-width: unset;
        font-size: 64px;
    }
}
.order-cancelled_learn-more-link-container {
    text-align: center;
    @media #{$medium-and-up} {
        text-align: left;
    }
}
.order-cancelled_learn-more-link {
    display: inline-block;
    width: 100%;
    max-width: 292px;
    border: 3px #005c58 solid;
    border-radius: 92px;
    padding: 0.5rem 0;
    margin-top: 2rem;
    text-align: center;
    text-decoration: underline;
    color: $gcg-purple;
    font-weight: bold;
}
// End orderCancelled.html.twig
